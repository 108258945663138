import React, { useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../../lib/helpers';
import BlockText from '../block-text';
import { useBreakpoint } from '../../lib/helpers';
import * as styles from './blogSection.module.css';

const BlogSection = (props) => {
  const { allSanityPost } = useStaticQuery(graphql`
    query BlogPostsQuery {
      allSanityPost(filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }) {
        edges {
          node {
            id
            publishedAt
            slug {
              current
            }
            thumbnailImage {
              alt
              asset {
                url
                _rev
                assetId
                _id
                gatsbyImageData(layout: FIXED, width: 400)
              }
            }
            categories {
              title
              id
            }
            _rawExcerpt(resolveReferences: { maxDepth: 10 })
            title
          }
        }
      }
    }
  `);

  const [isMobile, setIsMobile] = useState(false);
  // Breakpoints
  const isSmMin = useBreakpoint('smMin');

  useEffect(() => {
    if (!isSmMin) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const postNodes = allSanityPost.edges.length
    ? mapEdgesToNodes(allSanityPost)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const recentPosts = postNodes.slice(0, 3);
  return (
    <section id="blog" className={styles.section}>
      <div className={styles.container}>
        <header className={styles.sectionHeader}>
          <h2>
            <span>Blog</span>
          </h2>
          <div className={styles.headerDesc}>
            <span>
              Some stories about web dev, travels, and other random things I feel like writing
              about.
            </span>
          </div>
        </header>
        <div
          className={classNames(styles.blogGrid, {
            [styles.col1]: recentPosts.length === 1,
            [styles.col2]: recentPosts.length === 2,
          })}
        >
          {recentPosts.map((post) => {
            return (
              <Fade bottom key={post.id}>
                <div className={styles.postItem}>
                  <Link to={`/blog/${post.slug.current}/`} className={styles.postImage}>
                    {post.thumbnailImage && (
                      <GatsbyImage
                        image={post.thumbnailImage.asset.gatsbyImageData}
                        alt={post.thumbnailImage.alt || ''}
                      />
                    )}
                  </Link>
                  <Link className={styles.postTitle} to={`/blog/${post.slug.current}/`}>
                    {post.title}
                  </Link>
                  <div className={styles.postExcerpt}>
                    {post._rawExcerpt && <BlockText blocks={post._rawExcerpt} />}
                  </div>
                </div>
              </Fade>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
