// extracted by mini-css-extract-plugin
export var blogGrid = "blogSection-module--blogGrid--58f84";
export var col1 = "blogSection-module--col1--4c12c";
export var col2 = "blogSection-module--col2--4af12";
export var container = "blogSection-module--container--613fe";
export var headerDesc = "blogSection-module--headerDesc--a849e";
export var postImage = "blogSection-module--postImage--b2d6a";
export var postItem = "blogSection-module--postItem--2a6a1";
export var postTitle = "blogSection-module--postTitle--98498";
export var section = "blogSection-module--section--3de49";
export var sectionHeader = "blogSection-module--sectionHeader--54484";